import Vue from 'vue';

import { map } from 'lodash';

export default {
    namespaced: true,
    state: {
        LAYOUT_ORDER_PLACING: 'order-placing',
        LAYOUT_ORDER_COMPOSITION: 'order-composition',

        URGENCY_ID_COMMON: 1,
        URGENCY_ID_SOFT_URGENT: 2,
        URGENCY_ID_URGENT: 3,

        layout: 'order-composition',

        basket: [],
        basketDraft: [],

        orderFormData: {
            order_name: null,
            payment_method: null,
            comment: null,
            catalog: null,
            product: null,
            kind: null,
            productFormat: null,
            principle: null,
            totalPrice: null,
            totalWeight: null,
            totalTime: null,
            totalMarkup: null,
            totalPriceForOne: null,
            count: 1,
            recommendations: {},
            listsCount: 1,
            min_count: 1,
            urgency_id: 1,
            manager: null,
        },

        deliveryFormData: {
            name: null,
            surname: null,
            patronymic: null,
            phone: null,
            delivery_type: null,
            delivery_option: null,
            address: null,
            hasLoadAddressList: false,
        },

        warehouseData: {},

        currentClient: null,
        company: null,

        paymentMethods: [],

        loadingGetList: false,
        hasLoadingSendOrder: false,
        loadingGetModificationTechId: false,
    },

    getters: {
        totalBasketTime(state) {
            return Math.max(...map(state.basket, (el) => el.data.time));
        },

        productFormatsList(state) {
            return (
                state.orderFormData.product?.formats.map((format) => ({
                    ...format,
                    title: format.title || format.original_title,
                })) || []
            );
        },

        orderUrgency(state) {
            if (!state.basket.length) return state.URGENCY_ID_COMMON;

            let common = 0,
                urgent = 0;

            state.basket.forEach((item) => (+item.data.urgency_id === state.URGENCY_ID_COMMON ? (common += 1) : (urgent += 1)));

            return common && urgent
                ? state.URGENCY_ID_SOFT_URGENT
                : !common && urgent
                  ? state.URGENCY_ID_URGENT
                  : state.URGENCY_ID_COMMON;
        },

        hasBasketWarehouseErrors(state) {
            return (
                !!Object.values(state.warehouseData).filter((value) => value.total > value.max && !value.is_preorder_available)
                    .length &&
                !!Object.keys(state.warehouseData).filter((key) => state.basket.map((item) => item.kind.id).includes(+key)).length
            );
        },

        hasWarehouseErrors(state) {
            return !!Object.values(state.warehouseData).filter((value) => value.total > value.max && !value.is_preorder_available)
                .length;
        },

        isWarehouseBasket(state) {
            return !!(state.basket.length === state.basket.filter((item) => !!item.warehouse).length && state.basket.length > 0);
        },
    },

    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout;
        },

        SET_PAYMENT_METHODS(state, list) {
            state.paymentMethods = list;
        },

        SET_CLIENT(state, client) {
            state.currentClient = client;
        },

        SET_COMPANY(state, company) {
            state.company = company;
        },

        SET_ORDER_FORM_DATA(state, { key, value }) {
            Vue.set(state.orderFormData, key, value);
        },

        SET_DELIVERY_FORM_DATA(state, { key, value }) {
            Vue.set(state.deliveryFormData, key, value);
        },

        SET_WAREHOUSE_DATA(state, { key, value }) {
            Vue.set(state.warehouseData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        ADD_ITEM_TO_DRAFT(state, item) {
            state.basketDraft.push(item);
        },

        ADD_ITEM_TO_BASKET(state, item) {
            state.basket.push(item);
        },

        MOVE_TO_BASKET(state, index) {
            state.basket.push(state.basketDraft[index]);
            Vue.delete(state.basketDraft, index);
        },

        UPDATE_DELIVERY_FORM_DATA(state, formData) {
            Object.assign(state.deliveryFormData, formData);
        },

        UPDATE_ORDER_FORM_DATA(state, formData) {
            Object.assign(state.orderFormData, formData);
        },

        DELETE_ITEM_FROM_DRAFT(state, index) {
            Vue.delete(state.basketDraft, index);
        },

        DELETE_ITEM_FROM_BASKET(state, index) {
            Vue.delete(state.basket, index);
        },

        CHANGE_PRODUCT_MAKET(state, { index, path, is_pro_mockup, mockup_markup }) {
            Vue.set(state.basket[index].data, 'destination', path);
            Vue.set(state.basket[index].data, 'is_pro_mockup', is_pro_mockup);
            Vue.set(state.basket[index].data, 'price', state.basket[index].data.price + mockup_markup);
        },

        CHANGE_BASKET_ITEM(state, { index, formData }) {
            Vue.set(state.basket[index], 'data', { ...formData });
        },

        CHANGE_BASKET_ITEM_KIND(state, { index, formData }) {
            Vue.set(state.basket[index], 'kind', { ...formData });
        },

        RESET_WAREHOUSE_DATA(state) {
            state.warehouseData = {};
        },

        RESET_CURRENT_CLIENT(state) {
            state.currentClient = null;
        },

        RESET_DELIVERY_FORM_DATA(state) {
            state.deliveryFormData = {
                name: null,
                surname: null,
                patronymic: null,
                phone: null,
                delivery_type: null,
                delivery_option: null,
                address: null,
                hasLoadAddressList: false,
            };
        },

        RESET_STORE(state) {
            state.basket = [];
            state.basketDraft = [];

            state.currentClient = null;

            state.deliveryFormData = {
                name: null,
                surname: null,
                patronymic: null,
                phone: null,
                delivery_type: null,
                address: null,
                hasLoadAddressList: false,
            };

            state.orderFormData = {
                order_name: null,
                payment_method: null,
                comment: null,
                catalog: null,
                product: null,
                kind: null,
                productFormat: null,
                principle: null,
                totalPrice: null,
                totalWeight: null,
                totalTime: null,
                totalMarkup: null,
                count: 1,
                listsCount: 1,
                min_count: 0,
                urgency_id: 1,
                manager: null,
                recommendations: {},
            };

            state.warehouseData = {};
        },
    },

    actions: {
        SAVE_ORDER: async ({ state, rootState, rootGetters, getters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingSendOrder',
                    status: true,
                });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/orders`, formData, {
                    headers: {
                        'Currency-Code': rootState.selectedCurrency,
                        'Accept-Language': rootState.selectedLang,
                    },
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingSendOrder',
                    status: false,
                });
            }
        },

        GET_PAYMENT_METHODS: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/payment-methods`
                    : `/api/payment-methods`;

                let resp = await $axios.get(path);

                commit('SET_PAYMENT_METHODS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
