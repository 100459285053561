<template>
    <div class="section__wrap">
        <div class="section__cnt">
            <div class="section__main">
                <v-table
                    :headers="tableHeaders"
                    :items="list"
                    :hasLoadingList="loadingGetList"
                    :pagination="listPagination"
                    @changePage="onPaginationChangePage"
                    @changeLimit="onPaginationChangeLimit">
                    <tr v-for="(td, index) in list" :key="index">
                        <td>
                            <druk-link :path="{ name: 'typographies_item', params: { id: td.id } }" :label="td.title" />
                        </td>

                        <td>
                            <druk-link
                                class="druk-u-word-break-break-word"
                                :path="`https://${td.domain}`"
                                :label="`https://${td.domain}`"
                                :isExternal="true" />
                        </td>

                        <td>
                            <span
                                class="label"
                                :class="{
                                    [`label--${td.pricing_plan.name}`]: !!td.pricing_plan,
                                    'label--canceled': !td.pricing_plan,
                                }"
                                >{{ td.pricing_plan ? td.pricing_plan.name : $fn.capitalize($t('common.is_missing')) }}</span
                            >
                        </td>

                        <td>
                            <div class="_flex _flex_wrap druk-u-max-width-460">
                                <span
                                    v-for="(el, index) in td.modules"
                                    :key="index"
                                    class="label druk-u-margin-right-4 druk-u-margin-bottom-4">
                                    {{ el.title }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </v-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import tableFiltering from '@/mixins/table-filtering.mixin.js';
    import moduleBuild from '@/mixins/module-build.mixin.js';

    export default {
        name: 'typographies-section',

        mixins: [tableFiltering, moduleBuild],

        computed: {
            ...mapState({
                list: (state) => state.typographies.list,
                listPagination: (state) => state.typographies.listPagination,

                loadingGetList: (state) => state.typographies.loadingGetList,
            }),
            ...mapGetters({
                listActiveFilter: 'typographies/listActiveFilter',
            }),

            tableHeaders() {
                return [
                    { key: 'title', text: this.$t('table.title') },
                    { key: 'domain', text: this.$t('table.domain') },
                    { key: 'pricing_plan', text: this.$t('pricing_plan.title') },
                    { key: 'modules', text: this.$t('table.modules') },
                ];
            },
        },

        methods: {
            ...mapMutations({
                UPDATE_LIST_FILTER: 'typographies/UPDATE_LIST_FILTER',
                RESET_STORE: 'typographies/RESET_STORE',
            }),

            ...mapActions({
                GET_LIST: 'typographies/GET_LIST',
            }),

            async getList() {
                await this.GET_LIST();
                this.setFilterDataToUrl();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
