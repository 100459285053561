export default {
    namespaced: true,

    state: {
        loadingAddClientsToCompany: false,
        loadingDeleteClientsFromCompany: false,
    },

    actions: {
        ADD_CLIENTS_TO_COMPANY: async ({ state, rootState, rootGetters, commit, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingAddClientsToCompany', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies/${id}/clients`;

                let resp = await $axios.post(path, formData);

                dispatch('companies/GET_ITEM', id, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingAddClientsToCompany', status: false });
            }
        },

        DELETE_CLIENTS_FROM_COMPANY: async ({ state, rootState, rootGetters, commit, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteClientsFromCompany', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies/${id}/clients`;

                let resp = await $axios.delete(path, {
                    data: formData,
                });

                dispatch('companies/GET_ITEM', id, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteClientsFromCompany', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },
};
