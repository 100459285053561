import Vue from 'vue';

import clients from './clients';
import orders from './modules/orders';
import drafts from './modules/drafts';
import transactions from './modules/transactions';

const DEFAULT_FILTERS = {
    full_name: null,
    with_trashed: 0,
    page: null,
    limit: 30,
    sort: 'new',
    direction: 'desc',
};

const DEFAULT_FORM_DATA = {
    full_name: null,
    responsible_person: null,
    code: null,
    location: null,
    bank: null,
    bill: null,
    mfo: null,
    note: null,
};

export default {
    namespaced: true,

    modules: {
        clients,
        orders,
        drafts,
        transactions,
    },

    state: {
        TRASHED_OPTIONS: [
            {
                key: 'companies.filters_trashed.with_trashed',
                value: 1,
            },
            {
                key: 'companies.filters_trashed.without_trashed',
                value: 0,
            },
        ],

        INACTIVE_FILTERING_KEYS: ['with_trashed'],

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        item: {},

        formData: { ...DEFAULT_FORM_DATA },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingRestoreItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        formattedList(state) {
            return [...state.list].map((item) => ({
                ...item,
                responsible_person: item.clients?.find((client) => client.is_responsible_person_company),
            }));
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies`;

                let resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, getters, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies`;

                let resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        RESTORE_ITEM: async ({ state, getters, rootGetters, commit, dispatch }, props = { formData: {}, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingRestoreItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies/${props.id}/restore`;

                let resp = await $axios.post(path, props.formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingRestoreItem', status: false });
            }
        },

        DELETE_ITEM: async ({ state, getters, rootGetters, commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/companies/${id}`;

                let resp = await $axios.delete(path);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = Object.assign(state.formData, { ...formData });
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = { ...DEFAULT_FORM_DATA };
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },
};
